<template>
  <footer>
    <div class="content">
      <!-- Контакти -->
      <div class="contacts">
        <div class="block">
          <span class="title">{{ $t('footer.contacts_title') }}</span>
          <span>
            <span>{{ $t('footer.email') }}:</span><br>
            <a href="mailto:office.fortisbeauty@gmail.com">office.fortisbeauty@gmail.com</a>
          </span>
        </div>
        
        <ul class="phones">
          <li>
            <span>{{ $t('footer.retail')}}:</span><br>
            <a href="tel:+380973261202">+380973261202</a>
          </li>
          <li>
            <span>{{ $t('footer.wholesale')}}:</span><br>
            <a href="tel:+380509719195">+380509719195</a>
          </li>
        </ul>
        <span>{{ $t('footer.working_hours') }}</span>
        <br>
        <span>{{ $t('footer.working_hours_alt') }}</span>
      </div>

      <!-- Інформація та інші блоки -->
      <div class="links">
        <div class="info">
          <span class="title">{{ $t('footer.info_title') }}</span>
          <ul>
            <li><a href="/catalogue">{{ $t('footer.catalog') }}</a></li>
            <li><a href="/brands">{{ $t('footer.brands') }}</a></li>
            <li><a href="/page/about">{{ $t('footer.about') }}</a></li>
            <li><a href="/blog">{{ $t('footer.blog') }}</a></li>
            <li><a href="/contacts">{{ $t('footer.contacts') }}</a></li>
          </ul>
        </div>

        <div class="block partners">
          <span class="title">{{ $t('footer.partners_title') }}</span>
          <ul>
            <li><a href="/opt">{{ $t('footer.cooperation') }}</a></li>
            <li><a href="/core/partner">{{ $t('footer.become_partner') }}</a></li>
          </ul>
        </div>

        <div class="block clients">
          <span class="title">{{ $t('footer.clients_title') }}</span>
          <ul>
            <li><a href="/page/delivery">{{ $t('footer.delivery') }}</a></li>
            <li><a href="/page/payment">{{ $t('footer.payment') }}</a></li>
            <li><a href="/page/terms-of-use/">{{ $t('footer.terms_of_use') }}</a></li>
          </ul>
        </div>
      </div>

      <!-- Оплата та соціальні мережі -->
      <div class="payment">
        <div class="block payment">
          <span class="title">{{ $t('footer.payment_methods') }}</span>
          <ul class="pay_methods">
            <li>
              <img src="@/assets/img/paymethods.png" alt="paymethods">
            </li>
          </ul>
        </div>

        <div class="block payment">
          <span class="title">{{ $t('footer.socials') }}</span>
          <ul class="socials">
            <li>
              <a href="https://www.instagram.com/fortisbeauty.ua/">
                <img src="@/assets/img/instagram.png" alt="instagram" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/fortisbeauty.store/">
                <img src="@/assets/img/facebook.png" alt="facebook" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
  
    };
  }
};
</script>

  
  <style scoped>
  footer {
    position: relative;
    padding: 32px 0;
    background-color: whitesmoke;
    margin-top: 48px;
  }
  @import '@/assets/css/base/footer.css';
  </style>
  