<template>
  <div class="order__form">
    <div v-if="isLoading" class="overlay">
      <div class="spinner"></div>
    </div>

    <!-- Step 1: Personal Information -->
    <form v-if="currentStep === 1" @submit.prevent="goToDeliveryStep" class="form validate personal-info__form">
      <span class="title">{{ $t('order.personal_info') }}</span>
       <!-- Promo Code Section -->
       <div class="fields">
        <label>
          <input
            v-model="promoCode"
            type="text"
            :placeholder="$t('order.promo_code')"
          />
          <button type="button" class="btn btn-secondary" @click="applyPromoCode">
            {{ $t('order.apply_promo') }}
          </button>
          <p v-if="promoMessage" class="info-text promo-message">{{ promoMessage }}</p>
          <p v-if="promoError" class="error-msg promo-error">{{ promoError }}</p>
        </label>
      
      </div>
      <div class="fields">
        <label>
          <input
            v-model="orderData.first_name"
            type="text"
            :placeholder="$t('order.first_name')"
            required
            :class="{ 'is-invalid': errors.first_name }"
            @blur="validateField('first_name')"
          />
          <span v-if="errors.first_name" class="error-msg">{{ $t('order.errors.first_name') }}</span>
        </label>
        <label>
          <input
            v-model="orderData.last_name"
            type="text"
            :placeholder="$t('order.last_name')"
            required
            :class="{ 'is-invalid': errors.last_name }"
            @blur="validateField('last_name')"
          />
          <span v-if="errors.last_name" class="error-msg">{{ $t('order.errors.last_name') }}</span>
        </label>
        <label>
          <input v-model="orderData.father_name" type="text" :placeholder="$t('order.father_name')" />
        </label>
        <label>
          <input
            v-model="orderData.phone"
            type="text"
            :placeholder="$t('order.phone')"
            required
            :class="{ 'is-invalid': errors.phone }"
            @blur="validateField('phone')"
          />
          <span v-if="errors.phone" class="error-msg">{{ $t('order.errors.phone') }}</span>
        </label>
        <label>
          <input
            v-model="orderData.email"
            type="email"
            :placeholder="$t('order.email')"
            :class="{ 'is-invalid': errors.email }"
            @blur="validateField('email')"
          />
          <span v-if="errors.email" class="error-msg">{{ $t('order.errors.email') }}</span>
        </label>
      </div>
      <div class="order_button grid-hd">
        <button type="submit" class="btn btn-primary">{{ $t('order.add_delivery_info') }}</button>
      </div>
    </form>

    <!-- Step 2: Delivery and Payment Information -->
    <form v-if="currentStep === 2" @submit.prevent="submitOrder" class="form validate delivery-info__form">
      <span class="title">{{ $t('order.delivery_payment') }}</span>
      <div class="fields">
        <span class="subtitle">{{ $t('order.select_delivery') }}</span>
        <ul class="delivery-types">
          <li class="delivery-types__item" @click="selectDelivery('newpost')" :class="{ selected: orderData.deliveryType === 'newpost' }">
            <a href="javascript:void(0)">
              <img src="@/assets/img/newpost.png" :alt="$t('order.delivery_types.newpost')" />
              <span>{{ $t('order.delivery_types.newpost') }}</span>
            </a>
          </li>
          <li class="delivery-types__item" @click="selectDelivery('curier')" :class="{ selected: orderData.deliveryType === 'curier' }">
            <a href="javascript:void(0)">
              <img src="@/assets/img/courier.png" :alt="$t('order.delivery_types.curier')" />
              <span>{{ $t('order.delivery_types.curier') }}</span>
            </a>
          </li>
        </ul>

        <!-- Fields for the selected delivery method -->
        <div v-if="orderData.deliveryType === 'curier'" class="delivery__type-form">
          <label>{{ $t('order.city') }}: Київ</label>
          <label><input v-model="orderData.curier_info.street" type="text" :placeholder="$t('order.street')" required /></label>
          <label><input v-model="orderData.curier_info.house" type="text" :placeholder="$t('order.house')" required /></label>
          <label><input v-model="orderData.curier_info.appartment" type="text" :placeholder="$t('order.apartment')" /></label>
        </div>

        <div v-else-if="orderData.deliveryType === 'newpost'" class="delivery__type-form">
          <label><input v-model="orderData.np_info.city" type="text" :placeholder="$t('order.city_receipt')" required /></label>
          <label><input v-model="orderData.np_info.branch" type="text" :placeholder="$t('order.branch')" required /></label>
        </div>

        <span class="subtitle">{{ $t('order.select_payment') }}</span>
        <p v-if="deliveryInfoMessage" class="info-text">{{ deliveryInfoMessage }}</p>
        
        <div class="delivery-types check_payment">
          <button @click="selectPayment('card')" :class="{ 'selected': orderData.payment === 'card' }" type="button">
            {{ $t('order.payment_types.card') }}
          </button>
          <button v-if="orderData.deliveryType === 'curier'" @click="selectPayment('cash')" :class="{ 'selected': orderData.payment === 'cash' }" type="button">
            {{ $t('order.payment_types.cash') }}
          </button>
          <button v-if="orderData.deliveryType === 'newpost'" @click="selectPayment('prepayment')" :class="{ 'selected': orderData.payment === 'prepayment' }" type="button">
            {{ $t('order.payment_types.prepayment') }}
          </button>
        </div>

        <p class="info-text">{{ $t('order.total_amount') }}: {{ totalOrderAmount }} {{ $t('currency')}}</p>
        <button type="submit" class="btn btn-primary" :disabled="!canSubmitOrder">{{ $t('order.submit') }}</button>
      </div>
    </form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import orderService from '@/api/orderService';

export default {
  name: "OrderForm",
  data() {
    return {
      currentStep: 1,
      promoMessage: null,
      orderData: {
        first_name: "",
        last_name: "",
        father_name: "",
        phone: "",
        email: "",
        deliveryType: "newpost",
        np_info: {
          city: '',
          branch: '',
        },
        curier_info: {
          city: 'Kyiv',
          street: '',
          house: '',
          appartment: '',
        },
        payment: "card",
      },
      errors: {},
      isLoading: false,
      baseDeliveryCost: 150,
      freeDeliveryThreshold: 4000,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'getUser', 'getCourierAddress', 'getNewPostAddress']),
    ...mapGetters('orders', ['order']),
    ...mapGetters({
      isWhoosaler: 'auth/isWhoosaler',
      cartTotalPrice: 'cart/cartTotalPrice',
      cartItems: 'cart/cartItems'
    }),
    orderAmount() {
      return this.cartTotalPrice;
    },
    deliveryCost() {
      if (this.orderData.deliveryType === "curier" && this.orderAmount < this.freeDeliveryThreshold) {
        return this.baseDeliveryCost;
      }
      return 0;
    },
    totalOrderAmount() {
      return this.orderAmount + this.deliveryCost;
    },
     canSubmitOrder() {
      return (
        this.orderData.deliveryType &&
        this.orderData.payment &&
        (this.orderData.deliveryType === "curier" ? 
          this.orderData.curier_info.street && this.orderData.curier_info.house : 
          this.orderData.np_info.city && this.orderData.np_info.branch
        )
      );
    },

    deliveryInfoMessage() {
      if (this.orderData.deliveryType === "curier" && this.orderAmount >= this.freeDeliveryThreshold) {
        return this.$t('order.delivery.free');
      } else if (this.orderData.deliveryType === "newpost" && this.orderData.payment === "card") {
        return this.$t('order.delivery.newpost_card');
      } else if (this.orderData.deliveryType === "newpost" && this.orderData.payment === "prepayment") {
        return this.$t('order.delivery.newpost_prepayment');
      }
      return this.$t('order.delivery.free_threshold');
    }

  },
  methods: {
    ...mapActions('auth', ['fetchUser','fetchCourierAddress', 'fetchNewPostAddress']),
    ...mapActions('orders', ['createdOrder', 'setOrder']),
    ...mapActions('cart', ['applyPromo']),
    async applyPromoCode() {
      try {
        this.isLoading = true;
        const response = await orderService.setPromoCode(this.promoCode);
        this.promoMessage = this.$t('order.promo_success', {
          discount: response.discount,
          brand: response.brand,
        });
        await this.applyPromo({
          discount: response.discount,
          brand: response.brand
        });
        this.promoError = null;
      } catch (error) {
        this.promoMessage = null;
        this.promoError = this.$t('order.promo_error');
      } finally {
        this.isLoading = false;
      }
    },
    async fetchUserData() {
  if (this.isAuthenticated) {
    await this.fetchUser();
    await this.fetchCourierAddress();
    await this.fetchNewPostAddress();

    const user = this.getUser;
    const courier = this.getCourierAddress;
    const newPost = this.getNewPostAddress;

    if (user) {
      this.orderData = {
        ...this.orderData,
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        father_name: user.father_name || "",
        phone: user.phone || "",
        email: user.email || "",
      };
    }
    if (courier) {
      this.orderData.curier_info = { ...courier };
    }
    if (newPost) {
      this.orderData.np_info = { ...newPost };
    }
  }
},

    validateField(field) {
      const value = this.orderData[field];
      const phoneRegex = /^[+]?\d{9,}$/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      switch (field) {
        case 'first_name':
        case 'last_name':
          this.errors[field] = !value ? this.$t(`order.errors.${field}.empty`) : "";
          break;
        case 'phone':
          this.errors.phone = !value
            ? this.$t('order.errors.phone.empty')
            : !phoneRegex.test(value)
            ? this.$t('order.errors.phone.invalid')
            : "";
          break;
        case 'email':
          this.errors.email = value && !emailRegex.test(value)
            ? this.$t('order.errors.email.invalid')
            : "";
          break;
      }
    },
    validateForm() {
      const fieldsToValidate = ['first_name', 'last_name', 'phone', 'email'];
      fieldsToValidate.forEach(field => this.validateField(field));
      return Object.keys(this.errors).every(key => !this.errors[key]);
    },
    validateDeliveryForm() {
      const deliveryErrors = {};
      const { deliveryType, np_info, curier_info } = this.orderData;

      if (deliveryType === "curier") {
        if (!curier_info.street) deliveryErrors.street = this.$t('order.errors.curier_info.street');
        if (!curier_info.house) deliveryErrors.house = this.$t('order.errors.curier_info.house');
      } else if (deliveryType === "newpost") {
        if (!np_info.city) deliveryErrors.city = this.$t('order.errors.np_info.city');
        if (!np_info.branch) deliveryErrors.branch = this.$t('order.errors.np_info.branch');
      }

      this.errors = { ...this.errors, ...deliveryErrors };
      return Object.keys(deliveryErrors).length === 0;
    },
    goToDeliveryStep() {
      if (this.validateForm()) {
        this.currentStep = 2;
      }
    },
    selectDelivery(type) {
      this.orderData.deliveryType = type;
      this.orderData.payment = ""; // Reset payment selection
    },
    selectPayment(type) {
      this.orderData.payment = type;
    },
    async submitOrder() {
      if (!this.validateDeliveryForm()) return;

      this.isLoading = true;
      try {
        const orderDetails = {
          ...this.orderData,
          deliveryCost: this.deliveryCost,
          totalAmount: this.totalOrderAmount,
          isWholesale: this.isWhoosaler,
          cartItems: this.cartItems,
        };

        const createdOrder = await orderService.createOrder(orderDetails);
        this.setOrder(createdOrder);

        if (this.orderData.payment === 'cash') {
          this.$router.push({ name: 'OrderSuccessPage' });
        } else {
          this.$router.push({ name: 'PaymentPage' });
        }
      } catch (error) {
        console.error('Помилка при створенні замовлення:', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.fetchUserData();
  },
};
</script>


<style scoped>


.payment-options button.selected {
  border-color: var(--clr-3);
}
  .order__form {
    display: grid;
    grid-gap: 32px;
    width: 420px;
    padding: 24px;
    background-color: whitesmoke;
    border-radius: 4px;
    position: relative;
  }
 
  .order__form .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 32px;
  }
  
  .personal-info__form .fields,
  .delivery__type-form .fields {
    display: grid;
    grid-gap: 16px;
  }
  
  .delivery-types {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    margin-bottom: 40px;
  }
  @media(max-width:550px){
    .delivery-types {
      display: flex;
      gap: 30px;
      flex-direction: column;
  }
  .delivery-types__item a{
    align-items: center;
    justify-content: flex-start;
  }
}
  
  .delivery-types__item a {
    border: 2px solid rgb(228, 228, 228);
    border-radius: 4px;
    height: 120px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .delivery-types__item a span {
    font-size: 20px;
    margin: -25px;
  }
  .delivery-types__item.selected a {
    border-color: var(--clr-1);
    background-color: #99ecff50;
  }
  .delivery-types__item a img {
    position: absolute;
    display: block;
    height: 90px;
    object-position: center;
    object-fit: contain;
    margin: 8px;
}
  .error-msg {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
  
  .is-invalid {
    border-color: red;
  }
  @media (max-width: 1250px) {
    .order__form{
        width: calc(100% - 50px);
    }
  }
  .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.check_payment button {
  padding: 26px;
  border: 2px solid rgb(228, 228, 228);
  border-radius: 4px;
  background-color: var(--clr-2) !important;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.check_payment button.selected {
  border-color: var(--clr-3);
  background-color: var(--clr-3) !important;
}

/* Загальні стилі для секції промокоду */
.promo-section {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.promo-section label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.promo-section input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
  outline: none;
  transition: border-color 0.3s ease;
}

.promo-section input[type="text"]:focus {
  border-color: #007bff;
}

.promo-section .btn-secondary {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.promo-section .btn-secondary:hover {
  background-color: #0056b3;
}

/* Стилі для успішного повідомлення */
.promo-message {
  margin-top: 10px;
  color: #28a745;
  font-size: 14px;
  font-weight: 500;
  animation: fadeIn 0.3s ease-in-out;
}

/* Стилі для повідомлення про помилку */
.promo-error {
  margin-top: 10px;
  color: #dc3545;
  font-size: 14px;
  font-weight: 500;
  animation: fadeIn 0.3s ease-in-out;
}

/* Анімація для появи повідомлень */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

  </style>
  

