<template>
    <div class="search-results" v-if="localSearchQuery">
      <div v-if="localSearchQuery.length < minQueryLength" class="min-length-message">
        {{ $t('search.min_length', { count: minQueryLength }) }}
      </div>
      
      <div v-else-if="loading" class="loading-spinner">{{ $t('orders.loading') }}</div>
    
      <!-- Відображення карток продуктів -->
      <div v-else-if="products.length && !loading">
        <div class="product-cards">
          <div v-for="product in products" :key="product.id" class="product-card">
            <a :href="`/product/${product.slug}`">
              <img 
                :src="getFirstImage(product)" 
                alt="Product image" 
                class="product-image"
              />
              <div class="product-info">
                <h4 class="product-name">{{ product.name }}</h4>
                <p class="product-brand">{{ product.brand }}</p>
                <p class="product-category">{{ product.category }}</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      
      <div v-else-if="!loading && localSearchQuery && !products.length" class="no-results">
        {{ $t('search.nothing_found') }}
      </div>
    </div>
  </template>
  
  <script>
  import { searchProducts } from '@/api/productService';
  import { ref, watch, onMounted } from 'vue';
  
  export default {
    props: {
      searchQuery: {
        type: String,
        default: ''
      }
    },
    setup(props) {
      const localSearchQuery = ref(props.searchQuery);
      const products = ref([]);
      const loading = ref(false);
      const minQueryLength = 2; // Мінімальна довжина запиту
      const searchTimeout = ref(null);
      
      // Функція для отримання результатів пошуку
      const fetchSearchResults = async () => {
        if (!localSearchQuery.value || localSearchQuery.value.length < minQueryLength) {
          products.value = [];
          return;
        }
        
        loading.value = true;
        try {
          // Використовуємо функцію з productService
          const response = await searchProducts(localSearchQuery.value);
          products.value = response.data.products || [];
        } catch (error) {
          console.error("Error fetching search results:", error);
          products.value = [];
        } finally {
          loading.value = false;
        }
      };
      
      // Функція для отримання першого зображення продукту
      const getFirstImage = (product) => {
        const variant = product.variants?.[0];
        return variant && variant.images && variant.images.length 
          ? variant.images[0].l 
          : require('@/assets/img/no_image.png'); // Шлях до зображення за замовчуванням
      };
      
      // Спостерігаємо за зміною пошукового запиту з затримкою
      watch(() => props.searchQuery, (newQuery) => {
        localSearchQuery.value = newQuery;
        
        // Очищаємо попередній таймаут
        if (searchTimeout.value) {
          clearTimeout(searchTimeout.value);
        }
        
        // Встановлюємо новий таймаут для запобігання частих запитів
        searchTimeout.value = setTimeout(() => {
          if (localSearchQuery.value && localSearchQuery.value.length >= minQueryLength) {
            fetchSearchResults();
          } else {
            products.value = [];
          }
        }, 300); // 300мс затримка
      });
      
      // Ініціалізація при монтуванні компонента
      onMounted(() => {
        if (localSearchQuery.value && localSearchQuery.value.length >= minQueryLength) {
          fetchSearchResults();
        }
      });
      
      return {
        localSearchQuery,
        products,
        loading,
        minQueryLength,
        getFirstImage
      };
    }
  };
  </script>
  <style scoped>
  .search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    max-width: 1200px; /* Максимальна ширина для великих екранів */
    margin: 0 auto; /* Центрування */
    overflow-y: auto; /* Вертикальна прокрутка */
    max-height: 80vh; /* Максимальна висота для прокрутки */
    border-radius: 8px;
  }
  
  .product-cards, .variant-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 10px;
    width: 100%;
  }

  .product-card, .variant-card {
    width: 180px; /* Стандартна ширина картки */
    height: 280px; /* Стандартна висота картки */
    border: 1px solid #ddd;
    border-radius: 8px;
    padding-top: 10px;
    overflow: hidden;
    text-align: center;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.2s ease-in-out;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 424px) {
    .product-card { 
      width: 155px; /* Ширина картки на мобільних */
      height: 260px; /* Висота картки на мобільних */
    }
  }
  
  .product-image, .variant-image {
    width: 100%;
    height: 160px; /* Фіксована висота для зображень */
    object-fit: scale-down;
  }
  

  .product-name, .variant-value {
    font-size: 16px;
    margin: 5px 0;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .product-name {
    height: 40px;
    text-wrap: wrap;
  }
  
  .product-brand, .product-category, .variant-code, .variant-price {
    font-size: 14px;
    color: #555;
    text-align: center;
    margin: 2px 0;
  }
  
  .product-brand {
    color: red;
  }
  
  .loading-spinner, .no-results {
    text-align: center;
    padding: 15px;
    font-size: 16px;
    color: #333;
  }
  
  .min-length-message {
    text-align: center;
    padding: 15px;
    font-size: 16px;
    color: #666;
    background-color: #f8f8f8;
    border-radius: 8px;
    margin: 10px 0;
  }
  
  /* Відступи для різних розмірів екранів */
  @media (min-width: 768px) {
    .search-results {
      padding: 30px;
    }
  
    .product-card, .variant-card {
      width: 180px;
      height: 280px;
    }
  }
  
  @media (min-width: 1024px) {
    .search-results {
      padding: 40px;
    }
  
    .product-card, .variant-card {
      width: 200px;
      height: 300px;
    }
  }
  </style>
  