<template>
  <div class="home" role="main">
    <!-- Десктопна версія -->
    <section aria-label="Слайдер головної сторінки">
      <HomeSlider v-if="!isMobile && isSliderVisible" />
    </section>

    <!-- Categories -->
    <section class="home__categories" v-if="!isMobile" aria-labelledby="categories-heading">
      <div class="content">
        <h2 id="categories-heading" class="title1">{{ $t('sidebar.categories')}}</h2>
        <ul>
          <li v-for="category in categories" :key="category.id">
            <a :href="category.url" :aria-label="category.human || category.name">
              <img :src="category.image" :alt="category.name" loading="lazy" />
              <p class="name">{{ category.human ? category.human : category.name }}</p>
            </a>
          </li>
        </ul>
      </div>
    </section>

    <!-- Products (Новинки) -->
    <section aria-labelledby="latest-products-heading">
      <h2 id="latest-products-heading" class="visually-hidden">{{ $t('filter.sort.newest') }}</h2>
      <ProductsSlider
        v-if="isProductsVisible"
        :heading="$t('filter.sort.newest')"
        :products="products"
        @OpenWishList="openWish"
        @OpenCartList="openCart"
      />
    </section>

    <!-- About Section -->
    <section aria-label="Про нас">
      <HomeAbout :slogan="slogan" :logo="logo" />
    </section>

    <!-- Мобільна версія -->
    <section aria-label="Слайдер головної сторінки (мобільна версія)">
      <HomeSlider v-if="isMobile && isSliderVisible" />
    </section>

    <!-- Categories -->
    <section class="home__categories" v-if="isMobile" aria-labelledby="categories-heading-mobile">
      <div class="content">
        <h2 id="categories-heading-mobile" class="title1">{{ $t ('sidebar.categories')}}</h2>
        <ul>
          <li v-for="category in categories" :key="category.id">
            <a :href="category.url" :aria-label="category.human || category.name">
              <img :src="category.image" :alt="category.name" loading="lazy" />
              <p class="name">{{ category.human ? category.human : category.name }}</p>
            </a>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { fetchLatestProducts } from '@/api/productService';
import { fetchFirstLevelCategories } from '@/api/categoryService';
import { defineAsyncComponent } from 'vue';
import HomeAbout from '@/components/HomeAbout.vue';

export default {
  name: 'HomePage',
  components: {
    HomeSlider: defineAsyncComponent({
      loader: () => import('@/components/HomeSlider.vue'),
      delay: 200,
      timeout: 5000,
      suspensible: true,
      onError: (error, retry, fail) => {
        console.error('Error loading HomeSlider:', error);
        fail();
      }
    }),
    ProductsSlider: defineAsyncComponent({
      loader: () => import('@/components/ProductsSlider.vue'),
      delay: 200,
      timeout: 5000,
      suspensible: true,
      onError: (error, retry, fail) => {
        console.error('Error loading ProductsSlider:', error);
        fail();
      }
    }),
    HomeAbout,
  },
  data() {
    return {
      isMobile: false,
      isSliderVisible: false,
      isProductsVisible: false,
      products: [],
      categories: [],
      logo: require('@/assets/img/logo.png'),
    };
  },
  async mounted() {
    this.checkIfMobile();
    
    // Додаємо слухач для зміни мови
    this.$watch(
      () => this.$i18n.locale,
      () => {
        // При зміні мови оновлюємо дані
        this.fetchProducts();
        this.loadCategories();
        this.updateMetaTags(); // Оновлюємо метатеги при зміні мови
      }
    );
    
    setTimeout(() => {
      this.isSliderVisible = true;
    }, 300);

    await Promise.all([this.loadCategories(), this.fetchProducts()]);
    
    // Встановлюємо метатеги для SEO
    this.updateMetaTags();

    window.addEventListener('resize', this.checkIfMobile);
    
    // Додаємо preload для критичних ресурсів
    this.preloadCriticalResources();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
    
    // Очищаємо застарілі кеші
    this.cleanupOldCaches();
  },
  methods: {
    openWish() {
      this.$emit('OpenWishList');
    },
    openCart() {
      this.$emit('OpenCartList');
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    async loadCategories() {
      try {
        const currentLocale = this.$i18n.locale;
        const cacheKey = `categories_${currentLocale}`;
        const cachedCategories = localStorage.getItem(cacheKey);
        
        if (cachedCategories) {
          this.categories = JSON.parse(cachedCategories);
        } else {
          const data = await fetchFirstLevelCategories();
          this.categories = data;
          localStorage.setItem(cacheKey, JSON.stringify(data));
        }
      } catch (error) {
        console.error("Не вдалося завантажити категорії:", error);
      }
    },
    async fetchProducts() {
      try {
        const currentLocale = this.$i18n.locale;
        const cacheKey = `latestProducts_${currentLocale}`;
        const cachedProducts = localStorage.getItem(cacheKey);
        
        if (cachedProducts) {
          const parsedCache = JSON.parse(cachedProducts);
          
          // Перевіряємо, чи кеш не застарів (24 години)
          const currentTime = new Date().getTime();
          if (parsedCache._timestamp && (currentTime - parsedCache._timestamp < 24 * 60 * 60 * 1000)) {
            this.products = parsedCache.data;
          } else {
            // Кеш застарів, завантажуємо нові дані
            await this.loadFreshProducts(cacheKey);
          }
        } else {
          // Кешу немає, завантажуємо нові дані
          await this.loadFreshProducts(cacheKey);
        }
        
        // Показуємо продукти після завантаження
        this.isProductsVisible = true;
      } catch (error) {
        console.error('Error fetching latest products:', error);
      }
    },
    async loadFreshProducts(cacheKey) {
      const response = await fetchLatestProducts();
      this.products = response.data.results.map(product => ({
        id: product.id,
        name: product.name,
        unit: product.unit,
        brand: product.brand.name,
        human: product.human,
        url: `/product/${product.slug}`,
        variants: product.variant.map(variant => ({
          id: variant.id,
          code: variant.code,
          value: variant.value,
          price: variant.price,
          discount_price: variant.discount_price,
          images: variant.images,
          url: variant.url,
          whoosale_price: variant.whoosale_price,
          discount_whoosale_price: variant.discount_whoosale_price,
          stock: variant.stock,
          stock_opt: variant.stock_opt,
        })),
      }));
      
      // Кешуємо результат з урахуванням мови та додаємо timestamp
      localStorage.setItem(cacheKey, JSON.stringify({
        data: this.products,
        _timestamp: new Date().getTime()
      }));
    },
    // Додаємо preload для критичних ресурсів
    preloadCriticalResources() {
      const criticalImages = [
        // Додайте шляхи до критичних зображень
      ];
      
      criticalImages.forEach(imagePath => {
        const link = document.createElement('link');
        link.rel = 'preload';
        link.as = 'image';
        link.href = imagePath;
        document.head.appendChild(link);
      });
    },
    
    // Очищаємо застарілі кеші
    cleanupOldCaches() {
      const currentTime = new Date().getTime();
      const cacheLifetime = 24 * 60 * 60 * 1000; // 24 години
      
      // Перевіряємо всі ключі в localStorage
      Object.keys(localStorage).forEach(key => {
        // Перевіряємо, чи це наш кеш
        if (key.startsWith('latestProducts_') || key.startsWith('categories_')) {
          const cacheData = localStorage.getItem(key);
          
          try {
            const data = JSON.parse(cacheData);
            
            // Якщо в кеші є timestamp і він застарів, видаляємо кеш
            if (data._timestamp && (currentTime - data._timestamp > cacheLifetime)) {
              localStorage.removeItem(key);
            }
          } catch (error) {
            // Якщо виникла помилка при парсингу, просто видаляємо кеш
            localStorage.removeItem(key);
          }
        }
      });
    },
    
    // Встановлюємо метатеги для SEO
    updateMetaTags() {
      // Базові метатеги
      document.title = this.$t('meta.home_title') || 'Fortis Beauty - Ваш кращий постачальник косметики';
      
      // Знаходимо або створюємо метатег description
      let descriptionMeta = document.querySelector('meta[name="description"]');
      if (!descriptionMeta) {
        descriptionMeta = document.createElement('meta');
        descriptionMeta.setAttribute('name', 'description');
        document.head.appendChild(descriptionMeta);
      }
      descriptionMeta.setAttribute('content', this.$t('meta.home_description') || 'Fortis Beauty - широкий вибір косметики для обличчя, тіла та волосся. Доставка по всій Україні.');
      
      // Open Graph метатеги
      this.updateOrCreateMeta('property', 'og:title', this.$t('meta.home_title') || 'Fortis Beauty - Ваш кращий постачальник косметики');
      this.updateOrCreateMeta('property', 'og:description', this.$t('meta.home_description') || 'Fortis Beauty - широкий вибір косметики для обличчя, тіла та волосся. Доставка по всій Україні.');
      this.updateOrCreateMeta('property', 'og:type', 'website');
      this.updateOrCreateMeta('property', 'og:url', window.location.href);
      this.updateOrCreateMeta('property', 'og:image', window.location.origin + '/img/og-logo.jpg');
      
      // Twitter Card метатеги
      this.updateOrCreateMeta('name', 'twitter:card', 'summary_large_image');
      this.updateOrCreateMeta('name', 'twitter:title', this.$t('meta.home_title') || 'Fortis Beauty - Ваш кращий постачальник косметики');
      this.updateOrCreateMeta('name', 'twitter:description', this.$t('meta.home_description') || 'Fortis Beauty - широкий вибір косметики для обличчя, тіла та волосся. Доставка по всій Україні.');
      this.updateOrCreateMeta('name', 'twitter:image', window.location.origin + '/img/og-logo.jpg');
      
      // Додаємо структуровані дані (JSON-LD)
      this.addStructuredData();
    },
    
    // Допоміжний метод для оновлення або створення метатегів
    updateOrCreateMeta(attrName, attrValue, content) {
      let meta = document.querySelector(`meta[${attrName}="${attrValue}"]`);
      if (!meta) {
        meta = document.createElement('meta');
        meta.setAttribute(attrName, attrValue);
        document.head.appendChild(meta);
      }
      meta.setAttribute('content', content);
    },
    
    // Додаємо структуровані дані для SEO
    addStructuredData() {
      // Видаляємо попередні структуровані дані, якщо вони є
      const existingScript = document.querySelector('script[type="application/ld+json"]');
      if (existingScript) {
        existingScript.remove();
      }
      
      // Створюємо структуровані дані для організації
      const organizationData = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        'name': 'Fortis Beauty',
        'url': window.location.origin,
        'logo': window.location.origin + '/img/logo.png',
        'contactPoint': {
          '@type': 'ContactPoint',
          'telephone': '+380973261202',
          'contactType': 'customer service'
        }
      };
      
      // Додаємо структуровані дані для продуктів, якщо вони є
      if (this.products && this.products.length > 0) {
        const productListData = {
          '@context': 'https://schema.org',
          '@type': 'ItemList',
          'itemListElement': this.products.slice(0, 10).map((product, index) => ({
            '@type': 'ListItem',
            'position': index + 1,
            'item': {
              '@type': 'Product',
              'name': product.name,
              'description': product.human,
              'brand': {
                '@type': 'Brand',
                'name': product.brand
              },
              'url': window.location.origin + product.url
            }
          }))
        };
        
        // Додаємо структуровані дані на сторінку
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.textContent = JSON.stringify([organizationData, productListData]);
        document.head.appendChild(script);
      } else {
        // Якщо продуктів немає, додаємо тільки дані про організацію
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.textContent = JSON.stringify(organizationData);
        document.head.appendChild(script);
      }
    },
  },
};
</script>

<style scoped>
@import '@/assets/css/home/home.css';
.home__categories {
  min-height: 200px;
}
.home__categories ul li img {
  width: 100%;
}

/* Стиль для візуально прихованих елементів (для доступності) */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
</style>
