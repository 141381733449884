// Прямі імпорти компонентів
import HeaderComponent from '../components/HeaderComponent/HeaderComponent.vue'
import FooterComponent from '../components/FooterComponent.vue'
import ProductsSlider from '../components/ProductsSlider.vue'
import WishlistComponent from '../components/WishlistComponent.vue'
import CartComponent from '../components/CartComponent.vue'

export const criticalComponents = {
  HeaderComponent: HeaderComponent,
  FooterComponent: FooterComponent
}

export const lazyComponents = {
  ProductsSlider: ProductsSlider,
  WishlistComponent: WishlistComponent,
  CartComponent: CartComponent
} 