<template>
  <div v-if="products.length" class="content">
    <div class="home__products" ref="sliderRef">
      <!-- Заголовок і кнопки -->
      <div class="home__products__heading">
        <span class="title">{{ heading }}</span>
        <div class="buttons">
          <div class="button_prev" @click="slidePrev">
            <img 
              src="../assets/img/icons/arrow-prev.png" 
              alt="<" 
              width="20" 
              height="20"
              loading="lazy"
            />
          </div>
          <div class="button_next" @click="slideNext">
            <img 
              src="../assets/img/icons/arrow-next.png" 
              alt=">" 
              width="20" 
              height="20"
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <!-- Список продуктів у слайдері -->
      <div class="home__products__list">
        <Carousel
          v-model="currentSlide"
          :items-to-show="itemsToShow"
          :wrap-around="true"
          :transition="300"
          :breakpoints="breakpoints"
        >
          <Slide v-for="product in (loading ? skeletons : products)" :key="product.id">
            <ul class="product-list">
              <ProductCard
                v-if="!loading"
                :key="product.id"
                :product="product"
                @OpenWishList="openWish"
                @OpenCartList="openCart"
              />
              <!-- Скелетон для картки товару -->
              <div v-else class="product-card-skeleton">
                <div class="skeleton-image"></div>
                <div class="skeleton-text"></div>
                <div class="skeleton-button"></div>
              </div>
            </ul>
          </Slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import ProductCard from '@/components/ProductCard.vue'
import { useIntersectionObserver, useWindowSize } from '@vueuse/core'

export default defineComponent({
  name: 'HomeProducts',
  components: {
    Carousel,
    Slide,
    ProductCard,
  },
  props: {
    heading: {
      type: String,
      default: 'Новинки'
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const loading = ref(true)
    const skeletons = new Array(5).fill({})
    const sliderRef = ref(null)
    const currentSlide = ref(0)
    const { width } = useWindowSize()

    const breakpoints = {
      300: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
      700: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
      1024: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
    }

    const itemsToShow = computed(() => {
      if (width.value >= 1024) return 4
      if (width.value >= 700) return 3
      return 2
    })

    // Lazy loading для слайдера
    onMounted(() => {
      // Перевіряємо підтримку IntersectionObserver
      if ('IntersectionObserver' in window && sliderRef.value) {
        const { stop } = useIntersectionObserver(
          sliderRef,
          ([{ isIntersecting }]) => {
            if (isIntersecting) {
              loading.value = false
              stop()
            }
          },
          { threshold: 0.1 }
        )
      } else {
        // Якщо IntersectionObserver не підтримується, просто показуємо контент
        setTimeout(() => {
          loading.value = false
        }, 1000)
      }
    })

    const slideNext = () => {
      currentSlide.value = (currentSlide.value + itemsToShow.value) % props.products.length
    }

    const slidePrev = () => {
      currentSlide.value = (currentSlide.value - itemsToShow.value + props.products.length) % props.products.length
    }

    return {
      currentSlide,
      slideNext,
      slidePrev,
      itemsToShow,
      loading,
      skeletons,
      breakpoints,
      sliderRef
    }
  },
  
  methods: {
    openWish() {
      this.$emit('OpenWishList')
    },
    openCart() {
      this.$emit('OpenCartList')
    }
  }
})
</script>

<style scoped>
.carousel__slide {
  align-items: flex-end;
}

.product-list {
  width: 100%;
}

.home__products__heading .title {
  font-size: var(--h2);
  font-weight: 500;
}

.home__products__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
}

.home__products__heading .buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.home__products__heading .buttons > div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  margin-left: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  will-change: transform;
}

.home__products__heading .buttons > div:hover {
  background-color: whitesmoke;
  transform: translateY(-2px);
}

.home__products__heading .buttons > div img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

@media(max-width: 960px) {
  .home__products__heading .title {
    font-size: var(--h4);
  }
  .home__products__heading .buttons > div {
    width: 40px;
    height: 40px;
  }
  .home__products__heading .buttons > div img {
    width: 16px;
    height: 16px;
  }
}

.product-card-skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background: var(--color-background);
}

.skeleton-image {
  width: 200px;
  height: 200px;
  background: linear-gradient(
    90deg,
    rgba(224, 224, 224, 0.6) 25%,
    rgba(240, 240, 240, 0.6) 37%,
    rgba(224, 224, 224, 0.6) 63%
  );
  background-size: 400% 100%;
  border-radius: 8px;
  animation: skeleton-loading 1.4s ease infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@media(max-width: 768px) {
  .skeleton-image {
    height: 150px;
    width: 150px;
  }
}

.skeleton-text {
  width: 60%;
  height: 70px;
  background: linear-gradient(
    90deg,
    rgba(224, 224, 224, 0.6) 25%,
    rgba(240, 240, 240, 0.6) 37%,
    rgba(224, 224, 224, 0.6) 63%
  );
  background-size: 400% 100%;
  border-radius: 4px;
  animation: skeleton-loading 1.4s ease infinite;
}

.skeleton-button {
  width: 120px;
  height: 40px;
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    rgba(224, 224, 224, 0.6) 25%,
    rgba(240, 240, 240, 0.6) 37%,
    rgba(224, 224, 224, 0.6) 63%
  );
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
}
</style>
