<template>
  <div id="app">
    <!-- Header -->
    <HeaderComponent 
      @toggle-cart="toggleCartList" 
      @toggle-wishlist="toggleWishlist"
    />
    
    <!-- Main Content -->
    <main>
      <router-view 
        @OpenWishList="toggleWishlist"
        @OpenCartList="toggleCartList"
      />
    </main>

    <!-- Popular Products Section -->
    <section class="viewed-products">
      <ProductsSlider
        :heading="$t('products.popular')"
        :products="products"
        @OpenWishList="toggleWishlist"
        @OpenCartList="openCart"
      />
    </section>

    <!-- Footer -->
    <FooterComponent />

    <!-- Wishlist Sidebar -->
    <WishlistComponent 
      :isWishlistOpen="isWishlistOpen" 
      @close_open="Wish_to_cart"  
      @close="toggleWishlist" 
    />
    
    <!-- Cart Sidebar -->
    <CartComponent 
      :isCartOpen="isCartOpen" 
      @close="closeCart" 
    />

    <!-- Scroll-to-Top Button -->
    <button
      v-show="isVisible"
      @click="scrollToTop"
      class="back-to-top"
      :title="$t('buttons.scroll_to_top')"
    >
      {{ $t('buttons.scroll_to_top') }}
    </button>
  </div>
</template>


<script>
import { 
  onMounted, 
  onBeforeUnmount,
  reactive,
  toRefs
} from 'vue'
import { criticalComponents, lazyComponents } from './utils/componentLoader'
import { fetchTopProducts } from './api/productService'

export default {
  name: 'App',
  components: {
    ...criticalComponents,
    ...lazyComponents
  },
 
  setup() {
    const state = reactive({
      products: [],
      isWishlistOpen: false,
      isCartOpen: false,
      isVisible: false,
      data: null
    })

    // Завантаження популярних продуктів
    const loadTopProducts = async () => {
      try {
        const response = await fetchTopProducts()
        if (response?.data) {
          state.products = response.data.map(product => ({
            id: product.id,
            name: product.name,
            brand: product.brand?.name,
            unit: product.unit,
            human: product.human,
            url: `/product/${product.slug}`,
            variants: product.variant?.map(variant => ({
              id: variant.id,
              code: variant.code,
              value: variant.value,
              price: variant.price,
              discount_price: variant.discount_price,
              images: variant.images,
              url: variant.url,
              whoosale_price: variant.whoosale_price,
              discount_whoosale_price: variant.discount_whoosale_price,
              stock: variant.stock,
              stock_opt: variant.stock_opt,
            })) || [],
          }))
        }
      } catch (error) {
        console.error("Помилка завантаження продуктів:", error)
      }
    }

    // Оптимізований scroll handler з requestAnimationFrame
    let scrollTimeout
    const handleScroll = () => {
      if (scrollTimeout) {
        window.cancelAnimationFrame(scrollTimeout)
      }
      
      scrollTimeout = window.requestAnimationFrame(() => {
        state.isVisible = window.scrollY > 100
      })
    }

    onMounted(() => {
      // Прелоад критичних компонентів
      Object.values(criticalComponents).forEach(component => {
        if (typeof component.loader === 'function') {
          component.loader()
        }
      })

      loadTopProducts()
      window.addEventListener('scroll', handleScroll, { passive: true })
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll)
      if (scrollTimeout) {
        window.cancelAnimationFrame(scrollTimeout)
      }
    })

    return {
      ...toRefs(state),
      toggleWishlist: () => state.isWishlistOpen = !state.isWishlistOpen,
      toggleCartList: () => state.isCartOpen = !state.isCartOpen,
      scrollToTop: () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      Wish_to_cart: () => {
        state.isWishlistOpen = !state.isWishlistOpen
        state.isCartOpen = !state.isCartOpen
      },
      closeCart: () => state.isCartOpen = false,
      openCart: () => state.isCartOpen = true
    }
  }
}
</script>

<style>
@import './assets/css/base/reset.css';
@import './assets/css/base/base.css';
@import './assets/css/main/main.css';
@import './assets/css/main/ui.css';
@import './assets/css/main/colors.css';
@import './assets/css/main/fonts.css';
@import './assets/css/main/forms.css';
@import './assets/css/main/richtext.css';
@import './assets/css/main/sidebar.css';

/* Повний вміст main.css */
body {
    max-width: 100vw;
    position: relative;
    min-width: 320px;
	margin: auto;
    overflow-x: hidden;
    line-height: 1.75;
}
main {
    padding-top: 48px;
    min-height: calc(100vh - 360px);
    overflow: hidden;
    max-width: 100vw;
}

.content {
	position: relative;
    width: calc(100% - 128px);
	margin: 0 auto;
}

@media (max-width: 960px) {
    .content {
        width: calc(100% - 32px);
        margin: 0 auto;
    }
    main {
        padding-top: 80px;
        min-height: calc(100vh - 360px);
    }
}

@media (max-width: 640px) {
    .content {
        width: calc(100% - 24px);
        margin: 0 auto;
    }
    main {
        padding-top: 80px;
        min-height: calc(100vh - 360px);
    }
}

.block   {display: block;margin-bottom: 24px;}
.inloneblock {display: inline-block;}
.grid    {display: grid;}
.none    {display: none;}
.pointer {cursor: pointer;}
.left {float: left;}
.right {float: right;}

.center {
    text-align: center;
}
.hidden {
    display: none;
}

.divcenter {
    width: auto;
    margin-left: auto;
    margin-right: auto;
}
/* ToUpButton */
.back-to-top {
  border: none;
  display: block !Important;
  color: wheat;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  background-color: var(--clr-2);
  padding: 15px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.back-to-top:hover {
  background-color: var(--clr-3);
}

</style>
