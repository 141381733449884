import apiClient from './axios';

// Отримати 40 останніх новинок
export const fetchLatestProducts = () => {
  return apiClient.get('/shop/products/latest/');
};
// Забираємо популярні продукти 
export const fetchTopProducts = () => {
  return apiClient.get('/shop/products/top-viewed/');
};


// Отримати інформацію про конкретний продукт за його ID
export const fetchProductById = (id) => {
  return apiClient.get(`/shop/products/${id}/`);
};

// Отримати список продуктів (з можливістю додати фільтрацію, сортування)
export const fetchProducts = (params = {}) => {
  return apiClient.get('/shop/products/', { params });
};
// Отримати список продуктів (з можливістю додати фільтрацію, сортування)
export const fetchProductBySlug = (slug) => {
  return apiClient.get(`/shop/products/slug/${slug}/`);
};
export const fetchProductsByExternalSlug = (externalSlug) => {
  return apiClient.get(`/shop/products/external-slug/${externalSlug}/`);
};
export const fetchCategoryProducts = async ({ parent_slug, child_slug, brand_slug, page = 1, sort = "newest" }) => {
  try {
    // Формування URL з використанням значень за замовчуванням, якщо параметри відсутні
    const url = `/shop/products/category-products/?page=${page}&parent_slug=${parent_slug || ''}&child_slug=${child_slug || ''}&brand_slug=${brand_slug || ''}&sort=${sort}`;
    
    // Виконуємо запит до API
    const response = await apiClient.get(url);
    
    // Повертаємо дані відповіді
    return response.data;
  } catch (error) {
    // Виводимо помилку в консоль для налагодження
    console.error("Помилка завантаження продуктів за категорією та сортуванням:", error);
    throw error;
  }
};

// Функція для пошуку продуктів
export const searchProducts = (query) => {
  return apiClient.get('/shop/search/', { params: { q: query } });
};
