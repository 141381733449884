<template>
  <div class="header__block-main">
    <div class="content">
      <!-- Logo -->
      <a class="header__logo__wrapper" href="/">
        <img class="header__logo" src="@/assets/img/logo.png" alt="FortisBeauty" />
      </a>

      <!-- Burger Menu -->
      <div class="burger" @click="toggleMenu">
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>

      <!-- Search -->
      <div class="header__search">
        <input
          type="search"
          name="search"
          id="search"
          :placeholder="$t('header.search_placeholder')"
          v-model="searchQuery"
          @focus="isSearchFocused = true"
          @blur="handleSearchBlur"
        />
        <button v-if="searchQuery" @click="clearSearch" class="clear-button">
         <img src="@/assets/img/icons/svg/Close.svg" alt=""> 
      </button>
        <!-- Search component_chini :) -->
      
      </div>

      <!-- Actions -->
      <div class="header__actions">
        <!-- Phone -->
        <div class="header__phone">
          <ul>
            <li v-for="phone in translatedPhones" :key="phone.phone">
              <a :href="`tel:${phone.phone}`">{{ phone.name }} {{ phone.phone }}</a>
            </li>
          </ul>
        </div>

        <!-- Recall Button -->
        <div class="header__account">
          <button class="button button__recall" @click="recallMe">
            {{ $t('header.recall_button') }}
          </button>
        </div>
        <Modal v-if="showModal" :isVisible="showModal" @close="showModal = false">
          <ContactForm />
        </Modal>

        <!-- Account -->
        <div class="header__account">
          <a href="/profile">
            <img src="@/assets/img/icons/user/Avatar.svg" :alt="$t('header.account')" class="ico" />
          </a>
        </div>

        <!-- Wishlist -->
        <div class="header__wishlist" @click="toggleWishlist">
          <img src="@/assets/img/icons/svg/Heart.svg" :alt="$t('header.wishlist')" />
          <span class="header__qty header__wishlist-qty">{{ wishlistItemCount }}</span>
        </div>

        <!-- Cart -->
        <div class="header__cart" @click="toggleCartList">
          <img src="@/assets/img/icons/svg/Basket_2.svg" :alt="$t('header.cart')" />
          <span class="header__qty header__cart-qty">{{ cartItemCount }}</span>
        </div>
      </div>
    </div>

    <!-- Фоновий шар для закриття сайдбару -->
    <div v-if="isMenuOpen" class="sidebar-backdrop" @click="closeMenu"></div>

    <!-- Sidebar Menu -->
    <Sidebar :isOpen="isMenuOpen" :logo="logo" @toggle-wishlist="toggleWishlist"  @menu-closed="handleMenuClosed" />
  </div>
  <SearchResults :searchQuery="searchQuery" v-if="isSearchFocused || searchQuery" />
</template>

<script>
import { mapGetters } from 'vuex';
import Sidebar from "./SidebarComponent.vue";
import SearchResults from "./SearchResults.vue";
import Modal from '@/components/ModalComponent.vue';
import ContactForm from '@/components/ContactForm.vue';


export default {
  components: {
    Sidebar,
    SearchResults,
    Modal,
    ContactForm
  },
  computed: {
    ...mapGetters('cart', ['cartItemCount']),
    ...mapGetters('wishlist', ['wishlistItemCount']),

    translatedPhones() {
      return [
        { name: this.$t('header.retail'), phone: "+380973261202" },
        { name: this.$t('header.wholesale'), phone: "+380509719195" }
      ];
    }
  },
  data() {
    return {
      searchQuery: "",
      isMenuOpen: false,
      showModal: false,
      isSearchFocused: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    handleMenuClosed() {
      this.isMenuOpen = false;
    },
    recallMe() {
      this.showModal = true;
    },
    toggleWishlist() {
      this.$emit('toggle-wishlist');
    },
    toggleCartList() {
      this.$emit('toggle-cart');
    },
    switchLanguage(lang) {
      this.$i18n.locale = lang;
    },
    clearSearch() {
      this.searchQuery = "";
    },
    handleSearchBlur() {
      setTimeout(() => {
        this.isSearchFocused = false;
      }, 200);
    }
  }
};
</script>


<style scoped>
:root {
    --sidebar-width: 420px;
    --sidebar-inner-padding: 24px;
    --cart-counter-grid-box: 32px;
    --cart-counter-grid-gap: 4px;
}

.outer {
    position: fixed;
    width: 100%;
    display: block;
    visibility: hidden;
    opacity: 0;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 10;
    transition: all 0.2s ease-in-out;
}

.outer.active {
    visibility: visible;
    opacity: 1;
}

.sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* напівпрозорий чорний фон */
  z-index: 1000001;
  backdrop-filter: blur(8px); /* Ефект блюру */
}

.sidebar {
    position: fixed;
    display: grid;
    grid-template-rows: 80px 1fr auto;
    width: 88%;
    max-width: 520px;
    max-width: var(--sidebar-width);
    height: 100%;
    top: 0;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    z-index: 1000002;
    transition: 
        right 0.2s ease-in-out, 
        left 0.2s ease-in-out;
}
.sidebar.right {
    right: -440px;
}
.sidebar.right.active {
    right: 0px;
}
.sidebar.left {
    left: -440px;
}
.sidebar.left.active {
    left: 0;
}

.sidebar .close {
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 8px;
    right: 24px;
    top: 24px;
    opacity: 0.25;
    cursor: pointer;
}
.sidebar > .title {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--clr-1);
    padding: 0 16px;
    background-color: whitesmoke;
}

.sidebar > .main {
    width: calc(100% - var(--sidebar-inner-padding) * 2);
    padding: var(--sidebar-inner-padding);
    overflow-y: auto;
}

.sidebar > .actions {
    width: calc(100% - 48px);
    padding: var(--sidebar-inner-padding);
    height: calc(100% - 32px);
    box-shadow: 0 8px 16px rgb(0, 0, 0, 0.15);
    align-items: flex-end;
}
.header__search {
  position: relative;
  display: flex;
  align-items: center;
}

input[type="search"] {
  min-width: 100px;
  padding-right: 30px;
}

.clear-button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #666;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clear-button:hover {
  color: #333;
}
.clear-button img {
  width: 18px;
  height: auto;

}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}
.button__recall{
  padding: 8px 14px;
}
</style>
