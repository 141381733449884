import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; 
import i18n from './i18n'; 
import { VueReCaptcha } from 'vue-recaptcha-v3';

// Імпортуємо основні стилі
import './assets/css/critical.css'

// Відкладене завантаження не критичних стилів та скриптів
const loadDeferredResources = () => {
  // Стилі
  const styleSheets = [
    './assets/css/main/ui.css',
    './assets/css/main/forms.css',
    './assets/css/main/richtext.css',
    './assets/css/main/sidebar.css'
  ]
  
  styleSheets.forEach(href => {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = href
    document.head.appendChild(link)
  })

  // Third-party скрипти
  const thirdPartyScripts = [
    {
      src: 'https://www.googletagmanager.com/gtm.js',
      async: true,
      defer: true
    },
    {
      src: 'https://connect.facebook.net/en_US/fbevents.js',
      async: true,
      defer: true
    }
  ]

  thirdPartyScripts.forEach(script => {
    const scriptElement = document.createElement('script')
    Object.entries(script).forEach(([key, value]) => {
      scriptElement[key] = value
    })
    document.body.appendChild(scriptElement)
  })
}

// Створюємо додаток
const app = createApp(App)

// Використовуємо плагіни
app.use(router)
app.use(store)
app.use(i18n)
app.use(VueReCaptcha, {
  siteKey: '6LdL_KIqAAAAAIb3YwyYX5sc56ndq0jIPh2FaPA_',
  loaderOptions: {
    autoHideBadge: false,
    explicitRenderParameters: {
      badge: 'bottomleft', 
    },
  },
})

// Монтуємо додаток
app.mount('#app')

// Відкладене завантаження ресурсів
if (document.readyState === 'complete') {
  loadDeferredResources()
} else {
  window.addEventListener('load', loadDeferredResources)
}